import React from 'react' // eslint-disable-line

import styled from 'styled-components'
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px;
  width: 100%;
  height: 100%;
  color: #ffffff;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  background: #333333;
  border: 1px solid #333333;
  text-shadow: 1px 1px 2px #333333;
  > p {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    + p {
      margin-top: 5px;
    }

    svg {
      width: 20px;
      height: 20px;
      margin-right: 5px;
      &[data-icon='success'] {
        width: 20px;
        circle {
          fill: #ffffff;
          stroke: transparent;
        }
        path {
          stroke: #333333;
          stroke-width: 2px;
        }
      }
      &[data-icon='error'] {
        width: 20px;
        path {
          fill: #333333;
          stroke: #333333;
          stroke-width: 2px;
        }
      }
    }
    span {
      max-width: 600px;
      @media (max-width: ${(props) => props.theme.breakpoints[0]}) {
        max-width: 300px;
      }
    }
  }
`