import React, { useEffect, useState } from 'react'
import debounce from 'lib/debounce'
import FlashMessagesConnector from 'containers/FlashMessage'
import CheckIcon from 'svg/check.svg'
import ErrorIcon from 'svg/exclamation-point.svg'
import Portal from './Portal'
import type { Props } from './helpers'
import './helpers'
const messageDebounce = debounce()
const cleanupDebounce = debounce()
const MESSAGE_DURATION = 3500
const CLEANUP_DURATION = 500

const Component = (props: Props): React.ReactElement<React.ComponentProps<any>, any> => {
  const [isVisible, handleIsVisible] = useState(true)
  const { clearMessage, available, type, messages } = props

  const closeFlash = (ev) => {
    if (ev) {
      ev.preventDefault()
    }

    // How long after CLOSE to wipe out ALL messages
    cleanupDebounce(() => {
      clearMessage()
      handleIsVisible(true)
    }, CLEANUP_DURATION)
    handleIsVisible(false)
  }

  useEffect(() => {
    messageDebounce(() => {
      closeFlash()
    }, MESSAGE_DURATION)
  }, [messages])
  return (
    <>
      {available && (
        <Portal {...props} closeFlash={closeFlash} isVisible={isVisible} type={type}>
          {messages.map((message, key) => (
            <p key={key} data-type='message'>
              {type === 'error' ? (
                <ErrorIcon data-icon='error' />
              ) : (
                <CheckIcon data-icon='success' />
              )}
              <span data-type={`message-${type === 'error' ? 'error' : 'success'}`}>{message}</span>
            </p>
          ))}
        </Portal>
      )}
    </>
  )
}

export default FlashMessagesConnector(Component)